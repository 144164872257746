.card-view-post-ads{
    border-radius: 10px;
    padding: 5% 25%;
    text-align: left;
}

.ads-post-btn{
    text-align: right;
}

.card-view-post-ads-mobile{
    border-radius: 10px;
    padding: 5% 5%;
    text-align: left;
}

.selected-default-option{
    color: #a3a3a3;
}
