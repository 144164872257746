.ads-single-view-img {
    width: 600px;
    height: 600px;
    object-fit: cover;
}

.ads-title-left {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    margin-top: -12px;
}

.left-text {
    text-align: left;
}

.full-ads-div {
    padding: 20px;
}

.ads-icons {
    text-align: right;
    font-size: 30px;
    cursor: pointer;
}

.ads-view-small-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
}

.admin-icon-size{
    font-size: 24px;
}

.ads-view-main-image {
    width: 100%;
    height: 400px;
    object-fit: contain;
}

.ads-div-color {
    background-color: #ffffff;
    margin-left: 3.5%;
}

.ads-mobile-div-color{
    background-color: #ffffff;
}

.ads-image-arrow {
    font-size: 40px;
    margin-top: 30px;
    cursor: pointer;
}

.main-image-count-text {
    position: absolute;
    color: #ffffff;
    background-color: #000000;
    top: 0;
    right: 0;
    margin-top: 10px;
    padding: 0 5px 0 5px;
}

.ads-fav-icon {
    font-size: 24px;
}

.ads-main-des {
    background-color: #ffffff;
    padding: 10px;
}

.ad-post-by {
    font-weight: bold;
}

.ads-contact-a {
    color: #000000;
    font-size: 18px;
    background: none;
}

.ads-contact-icons {
    font-size: 24px;
}

.p-desc-span{
    color: #9c9c9c;
}

.posted_date_time{
    color: #9c9c9c;
}


.posted-date-text-left{
    text-align: left;
    margin-top: -16px;
}

.breadcrumb-custom .breadcrumb-item+.breadcrumb-item::before {
    content: ">";
}

.ads-contact-data-div-box{
    padding-top: 15px;
    border-bottom: 1px solid #cfcfcf;
    margin: 5px;
}

.ads-contact-data-div-mobile{
    padding-top: 15px;
    border-bottom: 1px solid #cfcfcf;
}

.ad-view-container {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
}

.breadcrumb-custom .breadcrumb-item a {
    color: #474747;
}

.ads-view-fav{
    padding: 15px;
}

.ads-mobile-view-small-image {
    width: 100%;
    height: 80%;
    object-fit: cover;
    cursor: pointer;
}

.ads-image-mobile-arrow {
    font-size: 25px;
    margin-top: 75%;
    cursor: pointer;
}

p{
    color: #404040;
}

h2,h6,a{
    color: #404040;
}