.dark-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
  }
  
  .overlay-text {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .overlay-text-red {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: rgb(245, 120, 120);
  }