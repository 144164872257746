.post-ads-form-box {
    border-radius: 10px;
    padding: 5% 20%;
    text-align: left;
}

.ads-post-btn {
    text-align: right;
}

.post-ads-form-box-mobile {
    border-radius: 10px;
    padding: 5% 5%;
    text-align: left;
}

.selected-default-option {
    color: #a3a3a3;
}

.priceTable th {
    width: 52%;
    text-align: left;
    padding: 8px;
}

.priceTable td {
    width: 48%;
    text-align: left;
    padding: 8px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #c0c0c0;
    z-index: 1;
    margin-left: -10px;
    margin-top: 5px;
}

.dropdown-bottom-label{
    width: 90%;
}

.dropdown:focus .dropdown-content,
.dropdown:hover .dropdown-content,
.dropdown:checked .dropdown-content {
    display: block;
}

.dropdown-content label {
    display: block;
    margin-left: 5px;
}

.dropdown-bottom-box{
    padding: 5px;
}

.dropdown-bottom-box input{
    margin-left: 10px;
}