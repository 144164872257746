.vertical-line {
    border-left: 1px solid #ddd;
    height: 100%;
    min-height: 600px;
    margin-top: -20px;
    padding-top: 16px;
}

.selected-page .page-link {
    background: #FFC107 !important;
}

.pagination-l {
    margin-left: 10px;
}

.card-img-left {
    width: 200px;
    height: 200px;
    margin-right: 20px;
    object-fit: cover;
}

.card-content {
    display: flex;
    flex-direction: column;
}

.card-title {
    font-size: 18px;
    text-align: left;
    font-weight: bold;
}

.card-text {
    text-align: left;
}

.card-details {
    margin-top: 5px;
    text-align: left;
}

.form-control-select-ads {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'><path d='M7.247 11.14 2.451 5.658c-.566-.634-.106-1.658.754-1.658h9.788c.86 0 1.32 1.024.754 1.658l-4.796 5.482a1 1 0 0 1-1.508 0z'/></svg>") no-repeat right 0.75rem center/8px 10px;
    background-color: #ffffff;
    padding-right: 1.5rem;
}

.view-details {
    float: left;
    margin-left: 2px;
}

.icon-size{
    font-size: 24px;
}

.form-control-ads-page-size {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'><path d='M7.247 11.14 2.451 5.658c-.566-.634-.106-1.658.754-1.658h9.788c.86 0 1.32 1.024.754 1.658l-4.796 5.482a1 1 0 0 1-1.508 0z'/></svg>") no-repeat right 0.75rem center/8px 10px;
    background-color: #ffffff;
    padding-right: 1.5rem;
    width: 60px;
}

.page-item {
    cursor: pointer;
}

.page-size-div {
    text-align: left;
}

.dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-top: 5px;
}

.red-dot {
    background-color: rgb(248, 38, 30);
}

.green-dot {
    background-color: rgb(76, 230, 76);
}

.blue-dot {
    background-color: rgb(40, 126, 255);
}