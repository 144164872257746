@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

body,
html {
  background-color: #ffffff;
  color: #000;
  font-family: "Cabin", sans-serif;
}

.nav-site-name {
  margin-left: 5px;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 2.5px;
}

.nav-site-name-web{
  margin-left: 5px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 2.5px;
  font-size: 34px;
  position: absolute;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar,
.footer {
  background: #26272B;
  color: #fff;
}

.navbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar-brand,
.nav-link {
  color: #fff !important;
}

.nav-link:hover {
  color: #ccc !important;
}

.hero {
  background-color: #f8f9fa;
}

.btn-black {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
}

.btn-black:hover {
  background-color: #555;
  border-color: #555;
}

.card {
  background-color: #fff;
  border: 1px solid #ccc;
}

.card-title,
.card-text {
  color: #000;
}

.footer {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}


.copyrights {
  text-align: left;
}

.search-bar {
  padding: 20px 0;
  text-align: center;
}

.form-select {
  max-width: 250px;
}

.main-search-btn {
  background-color: #000000;
  color: white;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px 20px;
  font-size: 25px;
  font-weight: bold;
}

.site-text-color{
  color: #CACACB;
}

.navbar-site-logo {
  margin-top: -10px;
  max-width: 100px;
}

.navbar-mobile-site-logo {
  margin-top: -10px;
  min-width: 80px;
  max-width: 100px;
}

.alertText{
  color: #ffffff;
}

.alert-cancel-btn{
  margin-left: 10px;
}

.site-brand-div {
  margin-top: 5px;
}

.localization-btn {
  margin-left: 5px;
}

.main-search-btn:hover,
.main-search-btn:focus .main-search-btn:active {
  background-color: #23272b;
  border-color: #1d2124;
}

.footer-logo {
  max-height: 20px;
  margin-bottom: 10px;
}

.footer-site-name {
  margin-left: 10px;
}

.nav-left-li {
  padding: 0 0 0 10px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
  float: right;
}

.search-bar-container {
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}

.search-bar-m-container {
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  top: 0;
  margin-bottom: 20px;
}

.all-ads-img{
    width: 100%;
    height: 150px;
    object-fit: contain;
    padding: 10px;
}

.red-color-label{
  color: red;
}

.div-home-bottom-contact {
  padding: 40px;
}

.contact-icon {
  font-size: 24px;
  color: #000000;
  /* Bootstrap primary color */
  margin-bottom: 10px;
}

.contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-email:hover {
  text-decoration: underline;
}

.cus-img-thumbnail {
  border: 1px solid #dee2e6;
}

.home-contact-icons {
  margin-right: 10px;
  margin-top: 5px;
  width: 20px;
}

.home-contact-a {
  text-decoration: none;
  color: #000;
}

.home-contact-p {
  font-size: 20px;
  font-weight: bold;
}

.home-contact-p:hover {
  color: #474747;
}

.mobile-view {
  display: none;
}

.nav-user-profile-icon{
  font-size: 24px;
}

.navbar-custom .navbar-toggler {
  border-color: #ffffff;
}

.navbar-custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28 255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 1000px) {
  .web-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }
}

.mobile-view-container{
  padding: 20px;
}

.card-view-login{
    border-radius: 10px;
    padding: 5% 20%;
    min-height: 80vh;
}

.card-view-login-mobile{
    border-radius: 10px;
    padding: 5% 15%;
    min-height: 80vh;
}

.ads-card-view{
  min-width: 260px;
  max-width: 290px;
}

.my-ads-card-view{
  width: 280px;
}