p{
    text-align: justify;
}

h4{
    text-align: left;
}

li{
    text-align: left;
}