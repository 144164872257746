/* Footer.css */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  /* Text color */
  font-size: 1rem;
  /* Adjust the font size */

  bottom: 0;
  width: 100%;
  font-size: 14px;
}

.footer h5{
  font-size: 16px;
}

.footer-bottom-text {
  text-align: left;
  float: left;
  word-wrap: break-word;
}

a{
  color: #ffffff;
  text-decoration: none;
}

a:hover{
  color: #8a8a8a;
}

.footer-about-p{
  text-align: justify;
}

.footer-contact-icons{
  font-size: 16px;
  margin-right: 10px;
  margin-top: 5px;
  color: #FFCA2C;
}

.footer-social-icons{
  font-size: 36px;
  margin-right: 10px;
  margin-top: 5px;
  background-color: #FFCA2C;
  padding: 10px;
  border-radius: 20px;
}

.copyright-a{
  color: #CACACB;
  text-decoration: underline;
}